import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cn from 'classnames'

import { HolidaySetBanner } from 'components/common'
import { fetchArticle } from 'components/blog/actions'
import { withMainLayout } from 'components/layouts'
import { BlogShare } from 'components/blog'
import { backUrl } from './index'
import SEO from 'components/seo'

import css from './style.module.scss'

function Article({
  fetchArticle,
  params,
}) {
  const [stateArticle, setArticle] = useState({
    id: -1,
    name: '',
    text: '',
  })

  useEffect(() => {
    fetchArticle(`/article/${params.id}/`)
      .then(result => {
        setArticle(result)
      })
  }, [])

  return (
    <React.Fragment>
      <SEO
        title={stateArticle.name}
      />
      <div className={css.articlePage}>
        <div className='container'>
          <div className={css.head}>
            <Link to={backUrl.to} className={cn('linkBack caption', css.back)}>
              {backUrl.name}
            </Link>
            <h1>{stateArticle.name}</h1>
          </div>
          <div
            className={css.body}
            dangerouslySetInnerHTML={{__html: stateArticle.text}}
          />
          {
            stateArticle.id > -1 &&
              <BlogShare/>
          }
        </div>
      </div>
      <HolidaySetBanner/>
    </React.Fragment>
  )
}

export default connect(
  null,
  dispatch => ({
    fetchArticle: bindActionCreators(fetchArticle, dispatch),
  })
)(withMainLayout(Article))